<template>
  <div class="personal-verification">
    <div class="principal-banner">
      <img src="../assets/cuprum-logo-white.png" alt="Cuprum logo" class="cuprum-logo" />
      <div class="principal-banner banner-title">
        Tu traspaso remoto a AFP Cuprum sin salir de casa
        <strong>#JuntosyConectados</strong>
      </div>
      <p class="principal-banner banner-subtitle">
        Para continuar con el proceso de traspaso ingresado por tu ejecutivo, es necesario
        que valides tu identidad.
      </p>
    </div>
    <div v-bind:class="inputContainerClass">
      <div class="action-container">
        <FulfillmentAuthentication v-show="!sendingCode" v-if="isCICUE && !showGetCode && !haveMessage" :rut="clientRut"
          :jwt="authToken" :channel-key="channelKey" :email="clientEmail" :url-redirect="urlCICUERedirect"
          :method="authMethod" :strict-name-validation="strictNameValidation" :timeout-ci="timeoutCi"
          :timeout-cue="timeoutCue" mode-ci-cue="VALIDATE" style="text-align: left;" />
        <div v-if="showGetCode && !showAuthMethod">
          <h3 class="title-get-code">Tu Asesoría Remota</h3>
          <p class="text-get-code">
            Ingresa el código de asesoría que ha facilitado tu ejecutivo.
          </p>
          <div class="form-get-code">
            <label for="code" class="label-get-code">
              <span>Código de Asesoría</span>
            </label>
            <input class="input input-get-code" v-model="codeField" id="code" type="text" placeholder=" A1234" required
              @keyup.enter="sendCode" />
            <div class="help-link">
              <a href="#/personal-verification" @click="showModal = true">¿Qué es el código de asesoría?</a>
            </div>
            <div v-if="haveMessage" class="alert-container">
              <div class="alert-icon">!</div>
              <div class="alert-menssage">
                Tu código de asesoría no es correcto. Vuelve a intentarlo o solicita uno
                nuevo a tu asesor remoto.
              </div>
            </div>
            <button class="fulfillment-button" id="codeSender" :disabled="disableButton" @click="sendCode">
              Iniciar Validación de Identidad
            </button>
          </div>
          <div class="contact-executive">
            Si aún no dispones de un código de asesoría, solicítalo a tu ejecutivo.
          </div>
        </div>
        <div v-if="showAuthMethod">
          <h3 class="title-get-code">¿Cómo quieres validar tu identidad?</h3>
          <div class="text-container">
            <span class="text-get-code">{{ textGetCode }}</span>
          </div>
          <button class="fulfillment-button" id="codeSender2" :disabled="disableButton" @click="createAuthMethod">
            {{
              isFacial
                ? "Validar con reconocimiento facial"
                : isCICUE
                  ? "Validar con Clave Única"
                  : "Validar con preguntas de seguridad"
            }}
          </button>
        </div>
        <div class="auth">
          <div v-if="!isCICUE">
            <FulfillmentAuthentication v-show="!sendingCode" v-if="!showGetCode && !haveMessage" :rut="clientRut"
              :company="company" :method="authMethod" :channel-key="authApiKey" :environment="environment"
              :color="color" :button-enabled="true" :button-label="'Validar cliente'" :dummy-mode="dummyMode"
              :trace-id="traceId" :kong-token="kongToken" :skip-first-screen="true" :kong-mode-j-w-t="true"
              :light-mode="true" :facial-timer="3" @auth="onAuthentication" @goBack="$emit('goBack')">
            </FulfillmentAuthentication>
          </div>
        </div>
        <div v-if="sendingCode" class="loader-container">
          <p>
            <Spinner />
            <br>Estamos Consultado tus datos. Esto puede durar unos segundos.
          </p>
        </div>
        <div v-if="haveMessage && (showGenericError || authEnded)" class="message-container">
          <img :src="messageImageUrl" alt="Error icon" />
          <span v-if="!showGenericError" class="message-title">{{ messageTitle }}</span>
          <span v-if="!showGenericError" class="message-text">{{ messageText }}</span>
          <span v-if="showGenericError" class="message-text">
            <b>No ha sido posible completar el flujo</b><br />
            Contáctate con tu ejecutivo para volver a iniciar la validación.
          </span>
        </div>
      </div>
    </div>
    <div class="bottom-banner" />
    <div class="footer">
      <img src="../assets/logo-super.png" class="logo-superintendencia" alt="logo superintendencia" />
      <p class="text-footer">
        Entrega tu opinión a la SUPERINTENDENCIA DE PENSIONES sobre la calidad de servicio
        de tu AFP,
        <a href=" https://es.research.net/r/spensiones" class="help-link">
          Comienza Aquí
        </a>
      </p>
    </div>
    <modal v-if="showModal" @close="showModal = false">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <slot name="header"> Código de Asesoría </slot>
              </div>
              <div class="modal-body">
                <slot name="body">
                  Es el código que tu ejecutivo te enviará y con el cual podrás ingresar a
                  contestar las preguntas de seguridad que validarán tu identidad para
                  realizar el traspaso de tus fondos.
                </slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button" @click="showModal = false">
                    Cerrar
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </modal>
  </div>
</template>

<script>
import Config from "@/services/config";
import { IPUtils } from "@/services/previred/ip-utils";
import { KongService } from "@/services/previred/kong-service";
import { PreviredService } from "@/services/previred/previred-service";
import { TraceService } from "@/services/previred/trace-service";
import { DummyTraceService } from "@/services/previred/trace-service-dummy";
import FulfillmentAuthentication from "fulfillment-cuprum-auth-widget";
import uuid from "uuid-random";
import { createLogger } from "../services/logger";
import {
  DummyPreviredServiceTool,
  PreviredServiceTool,
} from "../services/previred-service-tool";
import { Utils } from "../services/utils";
import Spinner from "./Spinner";

const logger = createLogger("PersonalVerification");
const FACIAL_RECOGNITION_METHOD = "facialRecognition";
const CICUE_METHOD = "CICUE";
const QUESTIONS_METHOD = "questions";
const CICUE_PROVIDER = "ECERT";
const GROUPER_STATUSES = {
  APPROVED: "APPROVED",
  EXPIRED: "EXPIRED",
  REJECTED: "REJECTED",
  ERROR: "ERROR"
}
const TRANSFER_FAILED_STATUSES = [
  GROUPER_STATUSES.EXPIRED,
  GROUPER_STATUSES.REJECTED,
  GROUPER_STATUSES.ERROR
];

const TOKEN_STATUS = {
  UNCONFIRMED: "UNCONFIRMED",
  PENDING: "PENDING",
  OK: "OK",
  EXPIRED: "EXPIRED",
};

export default {
  name: "PersonalVerification",
  components: { FulfillmentAuthentication, Spinner },
  props: {
    company: { type: String, default: "cuprum" },
    environment: { type: String, default: process.env.VUE_APP_BUILD_ENVIRONMENT },
  },
  data() {
    return {
      authEnded: false,
      authApiKey: process.env.VUE_APP_FULFILLMENT_SALESFORCE_API_KEY,
      authMethod: "",
      authToken: undefined,
      authStackUrl: process.env.VUE_APP_FULFILLMENT_AUTH_AWS_URL,
      authStatus: "",
      channelKey: "",
      clientEmail: "",
      clientRut: "",
      config: new Config(this.environment),
      disableButton: true,
      dummyMode: false,
      extraDataFlag: 1,
      haveMessage: false,
      isFacial: false,
      isCICUE: false,
      isPollingOn: false,
      kongToken: undefined,
      otiWebUrl: process.env.VUE_APP_FULFILLMENT_OTI_WEB_URL,
      pollingErrorRetries: parseInt(process.env.VUE_APP_PREVIRED_POLLING_ERROR_THRESHOLD),
      pollingIntervalInMillis: parseInt(process.env.VUE_APP_PREVIRED_POLLING_INTERVAL),
      pollingHasErrors: false,
      previredId: "",
      previredUrl: process.env.VUE_APP_FULFILLMENT_PREVIRED_API_JWT,
      sendingCode: false,
      showAuthMethod: false,
      showGenericError: false,
      showGetCode: true,
      showModal: false,
      step1: false,
      step2: false,
      step3: false,
      tokenStatus: undefined,
      traceId: TraceService.createTraceId(),
      urlCICUERedirect: process.env.VUE_APP_FULFILLMENT_ASESORIA_URL,
      validationTypeFacial: 1003,
      validationTypeCICUE: 1005,
      waiting: true,
      codeField: "",
      messageImageUrl:
        "https://static.dx.principal.cl/fulfillment/assets/auth/facial/error.png",
      messageTitle: "Lo sentimos, ha ocurrido un error",
      messageText: "Vuelve a intentar el proceso.",
      timeoutCi: "",
      timeoutCue: "",
      strictNameValidation: undefined,
    };
  },
  computed: {
    kongService() {
      if (this.kongToken) {
        return KongService.fromToken(this.kongToken);
      } else {
        throw new Error(
          "Missing parameter: either kong token or kong token credentials must be defined, found none"
        );
      }
    },
    traceService() {
      if (this.dummyMode) {
        return new DummyTraceService();
      } else {
        return new TraceService(
          this.kongService,
          process.env.VUE_APP_FULFILLMENT_TRACE_API_JWT,
          {
            traceId: this.traceId,
            channel: "previred-widget",
            company: "cuprum",
            operation: "personal-verification",
            clientId: this.clientRut,
          }
        );
      }
    },
    previredService() {
      return new PreviredService(this.kongService, this.traceService, this.previredUrl);
    },
    checkCode() {
      return this.codeField;
    },
    inputContainerClass() {
      switch (this.authMethod) {
        case FACIAL_RECOGNITION_METHOD:
          return 'facial-container';
        case CICUE_METHOD:
          return (this.isCICUE) ? 'cicue-container' : 'input-container';
        default:
          return 'input-container';
      }
    },
    textGetCode() {
      switch (this.authMethod) {
        case FACIAL_RECOGNITION_METHOD:
          return "Para validar identidad con reconocimiento facial, tu celular debe contar con cámara para poder escanear tu cédula de identidad.";
        case CICUE_METHOD:
          return "Para validar identidad con Clave Única, deberá ingresar su nombre, apellido y número de serie.";
        default:
          return "Para validar identidad con esta opción, deberás contestar algunas preguntas. Tienes sólo 2 intentos.";
      }
    },
  },
  watch: {
    showGenericError(value) {
      if (value)
        this.messageImageUrl = process.env.VUE_APP_STATIC_URL + "/auth/icon-error.png"
    },
    isCICUE(value) {
      this.isCICUE = value;
    },
    authMethod(value) {
      this.authMethod = value;
    },
    checkCode() {
      this.activeSubmit();
    },
    authStatus(value) {
      if (!this.showGenericError) {
        if (TRANSFER_FAILED_STATUSES.includes(value)) {
          this.messageImageUrl =
            process.env.VUE_APP_STATIC_URL + "/auth/icon-error.png";
          if (value === "EXPIRED") {
            this.messageImageUrl =
              process.env.VUE_APP_STATIC_URL + "/auth/icon-expired.png";
            this.messageTitle = "Se agotó el tiempo";
            this.messageText =
              "Se completó el tiempo para validar tu identidad, vuelve a intentarlo.";
          } else if (value === "REJECTED") {
            this.messageTitle = "No pudimos validar tu identidad";
            this.messageText =
              "Asegúrate de ingresar tus datos correctamente y vuelve a intentarlo.";
          } else {
            this.messageTitle = "Lo sentimos, ha ocurrido un error";
            this.messageText = "Vuelve a intentar el proceso.";
          }
        } else {
          this.messageImageUrl =
            process.env.VUE_APP_STATIC_URL + "/auth/icon-success.png";
          this.messageTitle = "Validación correcta";
          this.messageText = "Hemos validado con éxito tu identidad.";
        }
      }
    },
  },
  created() {
    this.changeFavicon();
    logger.info("Landing Started");
    this.dummyMode = this.$route.query.authDummyMode !== undefined;
    const url = new URL(window.location.href.replace(/#$/, ""));
    const advisoryCode = url.searchParams.get("code");
    const groupId = url.searchParams.get("group_id");
    const message = url.searchParams.get("message");
    const messageType = url.searchParams.get("message_type");
    logger.log("AdvisoryCode: ", advisoryCode);
    if (advisoryCode !== null) {
      this.saveTransferData(advisoryCode, groupId, message, messageType);
    } else if (groupId || message || messageType) {
      window.location(process.env.VUE_APP_FULFILLMENT_ASESORIA_URL)
    }
  },
  methods: {
    previredServiceTool() {
      if (!this.dummyMode) {
        return new PreviredServiceTool(this.kongService, this.config.previredUrl());
      } else {
        return new DummyPreviredServiceTool(this.config.previredUrl());
      }
    },
    checkBlockedRut() {
      this.disableButton = true;
      let info = new Utils();
      IPUtils.getBrowserIp()
        .then((myIP) => {
          const data = {
            channel: "5",
            ipClient: myIP,
            ipChannel: window.location.host,
            device: "No_Informado",
            operativeSystem: "No_Informado",
            system: info.obtainSystem(),
            module: "FF",
            authChannel: "previred-widget",
            user: this.clientRut.split("-")[0],
            browser: info.obtainBrowserName(),
            browserVersion: info.obtainBrowserVersion(),
            affiliateRut: this.clientRut,
          };
          this.previredServiceTool()
            .checkBlockedRut(data)
            .then((resp) => {
              if (!resp.data.blocked) this.disableButton = false;
              this.sendingCode = false;
              this.showAuthMethod = true;
            })
            .catch((error) => {
              this.sendingCode = false;
              this.showAuthMethod = true;
              logger.warn("Get blocked rut failed: " + error);
            });
        })
        .catch((error) => {
          this.sendingCode = false;
          this.showAuthMethod = true;
          logger.warn("Get IP failed: " + error);
        });
    },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    sendCode() {
      if (this.codeField) {
        if (!this.sendingCode) {
          this.sendingCode = true;
          if (this.dummyMode) {
            logger.info("Requesting JWT Dummy");
            logger.info("Codigo:" + this.codeField);
            logger.info("rut-cliente: " + "6987432-0");
            this.clientRut = "6987432-0";
            this.previredId = "transactionId";
            this.kongToken = process.env.VUE_APP_KONG_TOKEN_DUMMY;
            this.sendingCode = false;
            this.showAuthMethod = true;
            this.isFacial = true;
          } else {
            PreviredService.getJWTAutorization(
              process.env.VUE_APP_FULFILLMENT_PREVIRED_API,
              this.codeField,
              process.env.VUE_APP_FULFILLMENT_API_KEY
            )
              .then((response) => {
                if (response.advisoryCode === this.codeField.toUpperCase()) {
                  this.kongToken = response.jwt;
                  const data = this.parseJwt(response.jwt);
                  const myData = data.data.split(",");
                  this.clientRut = myData[1];
                  this.previredId = myData[0];
                  this.haveMessage = false;
                  if (
                    response.validationType &&
                    response.validationType === this.validationTypeCICUE &&
                    response.provider === CICUE_PROVIDER
                  ) {
                    this.isCICUE = true;
                    this.authToken = response.authJwt;
                    this.clientEmail = response.clientEmail;
                    this.channelKey = response.channelKey;
                    this.timeoutCi = response.timeoutCi;
                    this.timeoutCue = response.timeoutCue;
                    this.strictNameValidation = response.strictNameValidation;
                    this.disableButton = false;
                    this.sendingCode = false;
                    this.showAuthMethod = true;
                    this.urlCICUERedirect =
                      this.urlCICUERedirect + `?code=${this.codeField}`;
                  } else if (
                    response.validationType &&
                    response.validationType === this.validationTypeFacial
                  ) {
                    this.isFacial = true;
                    this.disableButton = false;
                    this.sendingCode = false;
                    this.showAuthMethod = true;
                  } else {
                    this.checkBlockedRut();
                  }
                } else {
                  logger.warn("AdvisoryCode inconsistent");
                  logger.warn(response);
                  this.haveMessage = true;
                  this.sendingCode = false;
                  this.showAuthMethod = false;
                  this.codeField = "";
                  this.disableButton = true;
                }
              })
              .catch((error) => {
                logger.warn("Get JWTAutorization Failed: " + error);
                this.haveMessage = true;
                this.sendingCode = false;
                this.showAuthMethod = false;
                this.codeField = "";
                this.disableButton = true;
              });
          }
        }
      }
    },
    createAuthMethod() {
      if (this.isFacial) this.authMethod = FACIAL_RECOGNITION_METHOD;
      else if (this.isCICUE) this.authMethod = CICUE_METHOD;
      else this.authMethod = QUESTIONS_METHOD;
      this.showAuthMethod = false;
      this.showGetCode = false;
    },
    createRemoteClientAuthRequest: function (dataResult, myIP) {
      const request = myIP ? { clientIP: myIP } : {};
      if (dataResult.status === "OK") {
        const previredData = dataResult.result.previredVerification;
        return {
          ...request,
          authenticationSuccess: true,
          userValidation: {
            rut: previredData.rut,
            name: previredData.name,
            lastName: previredData.lastName,
            motherLastName: previredData.motherLastName,
            auditCode: previredData.code,
            url: `${previredData.url}${previredData.code}`,
            userName: previredData.userUrl,
            pass: previredData.passUrl,
            provider: previredData.providerId,
            validationMethod: previredData.method,
          },
        };
      } else {
        return {
          ...request,
          authenticationSuccess: false,
          errorMessage: dataResult.reason,
        };
      }
    },
    onAuthentication(authResponse) {
      if (authResponse.result) {
        this.authEnded = true;
        const isAuthSuccess = authResponse.result.status === "APPROVED";
        this.sendingCode = true;
        IPUtils.getBrowserIp()
          .then((myIP) => {
            if (authResponse.result.method !== "FACIAL_RECOGNITION") {
              this.previredService
                .validateRemoteAssistedEnded(
                  this.createRemoteClientAuthRequest(authResponse, myIP),
                  this.previredId
                )
                .then(() => {
                  this.sendingCode = false;
                })
                .catch(error => { this.displayGenericError(error); });
            } else {
              this.previredService
                .validateRemoteAssistedEndedWithCertificate(
                  {
                    certificate: authResponse.result.certificate,
                    authenticationSuccess: isAuthSuccess,
                    clientIP: myIP,
                  },
                  this.previredId
                )
                .then(() => {
                  this.sendingCode = true;
                  this.pollToken(this.previredId, Date.now());
                })
                .catch(error => { this.displayGenericError(error); });
            }
          })
          .catch((error) => {
            logger.warn("Get IP failed: " + error);
            if (authResponse.result.method !== "FACIAL_RECOGNITION") {
              this.previredService
                .validateRemoteAssistedEnded(
                  this.createRemoteClientAuthRequest(authResponse),
                  this.previredId
                )
                .then(() => {
                  this.sendingCode = false;
                })
                .catch(error => { this.displayGenericError(error); });
            } else {
              this.previredService
                .validateRemoteAssistedEndedWithCertificate(
                  {
                    certificate: authResponse.result.certificate,
                    authenticationSuccess: isAuthSuccess,
                  },
                  this.previredId
                )
                .then(() => {
                  this.sendingCode = false;
                })
                .catch(error => { this.displayGenericError(error); });
            }
          });
      } else this.displayGenericError();
    },
    displayGenericError(error) {
      this.sendingCode = false;
      this.haveMessage = true;
      this.showGenericError = true;
      if (error)
        logger.warn(error);

    },
    pollToken(transactionId, startTime) {
      this.isPollingOn = true;
      let timePassed = Date.now() - startTime;
      if (timePassed < process.env.VUE_APP_PREVIRED_POLLING_MAX_TIME) {
        setTimeout(() => {
          this.previredService
            .getToken(transactionId, this.extraDataFlag)
            .then((response) => {
              this.tokenStatus = response.status;
              if (this.tokenStatus === TOKEN_STATUS.UNCONFIRMED) {
                this.pollToken(transactionId, startTime);
              } else if (this.tokenStatus === TOKEN_STATUS.PENDING) {
                if (this.dummyMode) {
                  this.isPollingOn = false;
                  this.tokenStatus = TOKEN_STATUS.OK;
                  const dummyResponse = {
                    transactionId: transactionId,
                    token:
                      "dummy.WxsbWVudC5hdXRoLWFwaSIsImlhdCI6MTYyNTc3NTkyNCwiZXhwIjoxNjI1Nzc2MjI0LCJzY29wZXMiOltdLCJ",
                    clientIP: "0.0.0.0",
                    executiveIP: "0.0.0.1",
                    clientProvider: "TOC",
                    executiveRut: "10994993-3",
                    advisoryToken: "asdf1234",
                  };
                  this.redirectToOTIWeb(dummyResponse);
                } else {
                  this.pollToken(transactionId, startTime);
                }
              } else if (this.tokenStatus === TOKEN_STATUS.OK) {
                this.haveMessage = true;
                this.messageImageUrl =
                  process.env.VUE_APP_STATIC_URL + "/auth/icon-success.png";
                this.messageTitle = "Validación correcta";
                this.messageText = "Hemos validado con éxito tu identidad.";
                this.isPollingOn = false;
                this.sendingCode = false;
                this.redirectToOTIWeb(response);
              } else {
                this.pollingHasErrors = true;
                this.isPollingOn = false;
                this.displayGenericError();
              }
            })
            .catch((error) => {
              this.pollingErrorRetries -= 1;
              if (this.pollingErrorRetries > 0) {
                this.pollToken(transactionId, startTime);
              } else {
                this.pollingHasErrors = true;
                this.isPollingOn = false;
                this.displayGenericError(error);
              }
            });
        }, this.pollingIntervalInMillis);
      } else {
        this.pollingHasErrors = true;
        this.isPollingOn = false;
        this.displayGenericError();
      }
    },
    redirectToOTIWeb(transactionData) {
      const request = {
        rut: Number(transactionData.clientRut.split("-")[0]),
        dv: transactionData.clientRut.split("-")[1],
        token: transactionData.token,
        rutVendedor: Number(transactionData.executiveRut.split("-")[0]),
        dvVendedor: transactionData.executiveRut.split("-")[1],
        codigoAsesoria: transactionData.advisoryCode,
        canal: 6,
      };
      const tokenUrl = uuid()
        .substring(0, 8)
        .concat(window.btoa(JSON.stringify(request)));
      const requestOTI = {
        otiToken: tokenUrl,
      };
      this.previredService.saveOTIData(requestOTI, this.previredId).then((response) => {
        if (response.result) {
          logger.info("OTI Token stored successfully");
        } else {
          logger.info("OTI Token was not stored");
        }
        const queryString = "?token=".concat(tokenUrl);
        window.location.href = this.otiWebUrl.concat(queryString);
      });
    },
    activeSubmit() {
      if (this.codeField) {
        this.disableButton = false;
      } else {
        this.disableButton = true;
      }
    },
    changeFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = process.env.VUE_APP_STATIC_URL + "/faviconCuprum.ico";
    },
    saveTransferData(advisoryCode, groupId, message, messageType) {
      logger.log("GroupId: ", groupId);
      this.showGetCode = false;
      this.sendingCode = true;
      this.authEnded = true;
      if (groupId && advisoryCode) {
        PreviredService.getJWTAutorization(
          process.env.VUE_APP_FULFILLMENT_PREVIRED_API,
          advisoryCode,
          process.env.VUE_APP_FULFILLMENT_API_KEY
        ).then((response) => {
          if (advisoryCode.toUpperCase() === response.advisoryCode) {
            this.authToken = response.authJwt;
            this.kongToken = response.jwt;
            this.channelKey = response.channelKey;
            const data = this.parseJwt(response.jwt);
            const myData = data.data.split(",");
            this.previredId = myData[0];
            if (groupId === "null" && message !== null && messageType !== null) {
              this.displayGenericError();
              const errorMessage = messageType + "\n" + message;
              this.previredService.validateRemoteAssistedCICUEEnded(
                this.createRemoteClientAuthCICUERequest(
                  this.channelKey, undefined, errorMessage, undefined
                ),
                this.previredId
              ).then((resp) => {
                this.authStatus = resp.status;
                console.warn("Error Message: ", resp.message)
                this.haveMessage = true;
                this.sendingCode = false;
              }).catch(error => { this.displayGenericError(error); })
            } else {
              IPUtils.getBrowserIp().then(myIp => {
                this.previredService.validateRemoteAssistedCICUEEnded(
                  this.createRemoteClientAuthCICUERequest(
                    this.channelKey, groupId, undefined, myIp
                  ),
                  this.previredId
                ).then((resp) => {
                  this.authStatus = resp.authentication.status
                  if (resp.status === TOKEN_STATUS.PENDING && this.authStatus === GROUPER_STATUSES.APPROVED)
                    this.pollToken(this.previredId, Date.now())
                  else {
                    this.haveMessage = true;
                    this.sendingCode = false;
                    logger.warn("previred transaction: ", resp.message)
                  }
                }).catch(error => { this.displayGenericError(error); })
              }).catch(() => {
                console.warn("IP error: can not get IP value")
                this.previredService.validateRemoteAssistedCICUEEnded(
                  this.createRemoteClientAuthCICUERequest(
                    this.channelKey, groupId, undefined, undefined
                  ),
                  this.previredId
                ).then((resp) => {
                  this.authStatus = resp.authentication.status
                  if (resp.status === TOKEN_STATUS.PENDING && this.authStatus === GROUPER_STATUSES.APPROVED)
                    this.pollToken(this.previredId, Date.now())
                  else {
                    this.haveMessage = true;
                    this.sendingCode = false;
                    logger.warn("previred transaction: ", resp.message)
                  }
                }).catch(error => { this.displayGenericError(error); })
              })
            }
          }
        }).catch(error => { this.displayGenericError(error); });
      }
    },
    createRemoteClientAuthCICUERequest(channelKey, groupId, errorMessage, myIP) {
      const clientIP = myIP ? { clientIP: myIP } : {};
      const clientGroupId = (groupId && !errorMessage) ? { groupId: groupId } : {};
      const clientErrorMessage = (errorMessage && !groupId) ? { errorMessage: errorMessage } : {};

      return {
        ...clientIP,
        ...clientGroupId,
        ...clientErrorMessage,
        channelKey: channelKey
      }
    },
  },
};
</script>

<style>
body {
  background-color: #d8d8d8;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  vertical-align: center;
  font-family: "FS Elliot Pro";
  color: #767676;
}

.text-container {
  display: flex;
}

.separation {
  margin: 25px 0px;
}

.message-container {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message-container>img {
  margin-bottom: 16px;
  font-family: FS Elliot Pro;
}

.message-title {
  display: block;
  color: #464646;
  font-size: 24px;
  text-align: center;
  width: 100%;
  font-weight: 300;
}

.message-text {
  display: block;
  color: #464646;
  font-size: 18px;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
}

.cicue-container {
  width: auto;
  height: auto;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 830px;
  padding-left: 42px;
  padding-right: 42px;
  padding-bottom: 25px;
  border-radius: 6px;
  background-color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .cicue-container {
    min-width: 30%;
    min-height: 50%;
    align-self: center;
    left: auto;
  }
}

@media only screen and (max-width: 768px) {
  .cicue-container {
    max-width: 95%;
    left: auto;
    align-self: center;
    min-height: 40%;
    min-width: 60%;
    translate: 0 10%;
    padding: 0;
  }
}
</style>

<style src="../assets/cuprum.css"></style>
<style src="fulfillment-cuprum-auth-widget/dist/fulfillment-cuprum-auth-widget.css"></style>
<style scoped src="../assets/style-personal-verification.css"></style>
